import React, { useState } from 'react';
import { Modal, Button } from '../../../components';
import css from './WishlistModal.module.css';
import {
  updateInProgressSelector,
  updateProfile,
} from '../../ProfileSettingsPage/ProfileSettingsPage.duck';
import { useDispatch, useSelector } from 'react-redux';
import { getWishlist, isArrayLength, upsertWishlistItem } from '../../../util/dataExtractors';
import { currentUserSelector } from '../../../ducks/user.duck';

function WishlistModal(props) {
  const {
    onManageDisableScrolling,
    isWishlistModalOpen,
    onToggleWishlistModal,
    listings,
    intl,
    selectedWishlistItem: wishlistItem,
  } = props;

  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector);
  const updateInProgress = useSelector(updateInProgressSelector);

  const wishlistItems = getWishlist(currentUser);

  // State for creating a new wishlist
  const [showCreateWishlist, setShowCreateWishlist] = useState(false);
  const [newWishlistName, setNewWishlistName] = useState('');

  const handleClose = () => {
    onToggleWishlistModal(false);
    setShowCreateWishlist(false);
  };

  const handleCreateWishlist = () => {
    setShowCreateWishlist(true);
  };

  const handleWishlistNameChange = event => {
    setNewWishlistName(event.target.value);
  };

  const saveNewWishlist = wishlistNameIfAny => {
    const wishlist = upsertWishlistItem(currentUser, {
      wishlistItem,
      wishlistName: newWishlistName || wishlistNameIfAny,
      listings,
    });
    dispatch(
      updateProfile({
        protectedData: {
          wishlist,
        },
      })
    ).then(() => {
      handleClose();
    });
  };

  const modalContent = showCreateWishlist ? (
    <div className={css.createWishlist}>
      <div className={css.formFld}>
        <label htmlFor="newWishlistName">{intl.formatMessage({ id: 'WishlistModal.title' })}</label>
        <input
          type="text"
          value={newWishlistName}
          onChange={handleWishlistNameChange}
          placeholder={intl.formatMessage({ id: 'WishlistModal.titlePlacehodler' })}
        />
      </div>
      <Button inProgress={updateInProgress} onClick={saveNewWishlist}>
        {intl.formatMessage({ id: 'WishlistModal.saveWishlist' })}
      </Button>
    </div>
  ) : (
    <div className={css.wishlistOverview}>
      <div className={css.wishlistContent}>
        {isArrayLength(wishlistItems)
          ? wishlistItems.map(wishlist => (
              <div
                key={wishlist?.name}
                className={css.wishlistItem}
                onClick={() => {
                  setNewWishlistName(wishlist?.name);
                  saveNewWishlist(wishlist?.name);
                }}
              >
                <div className={css.wishlistImg}>
                  <img src={wishlist?.coverImage} alt={wishlist?.name} />
                </div>
                <h2>{wishlist?.name}</h2>
                <p>
                  {wishlist?.ids?.length} {intl.formatMessage({ id: 'WishlistModal.saved' })}
                </p>
              </div>
            ))
          : null}{' '}
      </div>
      <Button inProgress={updateInProgress} onClick={handleCreateWishlist}>
        {intl.formatMessage({ id: 'WishlistModal.createNewWishlist' })}
      </Button>
    </div>
  );

  return (
    <Modal
      id="Wishlist.modal"
      isOpen={isWishlistModalOpen}
      onClose={handleClose}
      onManageDisableScrolling={onManageDisableScrolling}
      modalTitle={intl.formatMessage({
        id: showCreateWishlist ? 'WishlistModal.createList' : 'WishlistModal.addToList',
      })}
    >
      {modalContent}
    </Modal>
  );
}

export default WishlistModal;
